import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';
import { Tip } from '@/interfaces/models/Tip';
import { OrderStatus } from '@/enums/OrderStatus';
import { SalesOverview } from '@/interfaces/models/SalesOverview';
import { Pagination } from '@/interfaces/api/Pagination';

export default class OrderServiceApi extends ApiService<any> {
  protected exportServiceUrl: string = 'v1/export';
  constructor() {
    super('order', 'v2');
  }

  public orderCsv(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
  }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/${param.venue}/csv`, { params: param });
  }

  public orderPaymentMethodCsv(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
    customerGroup?: string;
    labels?: string[];
    format?: string;
  }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/order/csv`, { params: param });
  }

  // TODO add Form for this
  public salesCsv(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
    customerGroup?: string;
    labels?: string[];
  }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/sales/csv`, { params: param });
  }

  // TODO add Form for this
  public salesPdf(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
    customerGroup?: string;
    labels?: string[];
  }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/sales/pdf`, { params: param });
  }

  public salesGroup(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
    customerGroup?: string;
    labels?: string[];
  }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/revenue/csv?`, { params: param });
  }

  public salesOverview(param: { customerGroup: string; from: string; to: string }) {
    return axios.get(`${this.exportServiceUrl}/sales-overview`, { params: param, responseType: 'blob' });
  }

  // @deprecated Test order function is now in separate frontend
  public testOrder(param: { venue: string }): AxiosPromise<void> {
    return axios.get(`${this.getBaseUrl('v1')}/test/subway/${param.venue}`);
  }

  public orderStatisticsCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.exportServiceUrl}/statistic/csv`, { params: filter });
  }

  public promoCodeOrdersCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.exportServiceUrl}/promoCode/csv`, { params: filter });
  }

  public couponsCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.exportServiceUrl}/coupons/csv`, { params: filter });
  }

  public promoCodeSummaryCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.exportServiceUrl}/promoCode/summary/csv`, { params: filter });
  }

  public couponSummaryCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.exportServiceUrl}/coupons/summary/csv`, { params: filter });
  }

  public exportReport(filter: Filter): AxiosPromise<{ file: string; contentType: string }> {
    return axios.post(`${this.getBaseUrl('v1')}/data/analytics`, filter);
  }

  public downloadReport(data: { file: string; contentType: string }): AxiosPromise<any> {
    if (data.contentType === 'text/csv') {
      return axios.get(`${this.getBaseUrl('v1')}/data/analytics/${data.file}`);
    }

    return axios.get(`${this.getBaseUrl('v1')}/data/analytics/${data.file}`, { responseType: 'arraybuffer' });
  }

  public async exportTips(venue: string, from: string, to: string): Promise<AxiosResponse<Tip>> {
    return await axios.get(`${this.getBaseUrl('v2')}/tips/${venue}/${from}/${to}`);
  }
  public async exportTipsCsv(venue: string, from: string, to: string): Promise<AxiosResponse<Tip>> {
    return await axios.get(`${this.exportServiceUrl}/tips/${venue}/${from}/${to}`);
  }
  public retry(data: { order: string; status: OrderStatus }) {
    return axios.patch(`${this.getBaseUrl('v2')}/${data.order}/retry`, { status: data.status });
  }

  public getSales(filter?: Filter): AxiosPromise<SalesOverview[] | Pagination<SalesOverview>> {
    return axios.get(`${this.getBaseUrl()}/sales-overview`, { params: filter });
  }
}
